import { store } from './store'

export const SET_ACCESS_CODE = 'SET_ACCESS_CODE'

export const accessCode = (
  state = window.localStorage.getItem('accessCode') || '',
  { type, accessCode }
) => type === SET_ACCESS_CODE ? accessCode : state

export const setAccessCode = accessCode => {
  store.dispatch({ type: SET_ACCESS_CODE, accessCode })
  window.localStorage.setItem('accessCode', accessCode)
}
export const selectAccessCode = () => store.getState().accessCode
