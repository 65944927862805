import { SET_ACCESS_CODE } from './accessCode'
import { store } from './store'

const ADD_HEAP = 'ADD_HEAP'

export const heaps = (state = { }, { type, name, heap }) => {
  if (type === SET_ACCESS_CODE) return { }
  else if (type === ADD_HEAP) return { ...state, [name]: heap }
  else return state
}

export const heapLoaded = (name, heap) => store.dispatch({ type: ADD_HEAP, name, heap })
export const selectHeap = name => () => store.getState().heaps[name]
