import React from 'react'
import styled from 'styled-components'
import AccessCodeDialog from './AccessCodeDialog'
import { previewsApi } from './Api'
import { Connect } from './store/Connect'
import HeapPreview from './HeapPreview'

const IndexContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: #fddabc;
  min-height: 100vh;
`

const IndexRows = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

const IndexTitle = styled.div`
  font-size: 60px;
  font-variation-settings: 'wght' 250, 'ital' 0.6;
  text-decoration: underline;
  color: #161616;
  padding: 40px;
`

const Index = () => <Connect {...previewsApi}>{ previews => <IndexContainer>
  <IndexTitle>Curtis Family Photos</IndexTitle>
  <IndexRows>{ previews.map(heap => <HeapPreview key={heap.path} {...heap} />) }</IndexRows>
  <AccessCodeDialog />
</IndexContainer> }</Connect>

export default Index
