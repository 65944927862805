import { NULL_VALUE } from './store/Connect'
import { heapLoaded, previewsLoaded, selectAccessCode, selectHeap, selectPreviews } from './store'

const PREVIEWS_QUERY = `query GetHeapPreviews($accessCode: String) {
  heaps(accessCode: $accessCode) {
    path name preview description date
  }
}`

const HEAP_QUERY = `query GetHeap($heap: String!, $accessCode: String) {
  heap(heap: $heap, accessCode: $accessCode) {
    path
    copyright
    copyrightCovers
    cards {
      cardType
      path
      ... on PhotoCard {
        width
        height
        preview
        sources { src width }
        meta { title description copyright }
      }
      ... on TitleCard {
        title
        description
      }
    }
  }
}`

const fetchQuery = (query, variables) => window.fetch(`${process.env.API_ROOT}/query`, {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  },
  body: JSON.stringify({ query, variables })
}).then(response => response.json())

async function loadPreviews () {
  const accessCode = selectAccessCode()
  const { data, errors } = await fetchQuery(PREVIEWS_QUERY, { accessCode })
  if (errors != null) throw errors

  previewsLoaded(data.heaps)
}

const loadHeap = heap => async function () {
  const accessCode = selectAccessCode()
  const { data, errors } = await fetchQuery(HEAP_QUERY, { heap, accessCode })
  if (errors != null) throw errors

  heapLoaded(heap, data.heap || NULL_VALUE)
}

export const previewsApi = { selector: selectPreviews, loader: loadPreviews }
export const heapApi = heap => ({ selector: selectHeap(heap), loader: loadHeap(heap) })
