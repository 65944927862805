import { SET_ACCESS_CODE } from './accessCode'
import { store } from './store'

const SET_PREVIEWS = 'SET_PREVIEWS'

export const previews = (state = null, { type, previews }) => {
  if (type === SET_ACCESS_CODE) return null
  else if (type === SET_PREVIEWS) return previews
  else return state
}

export const previewsLoaded = previews => store.dispatch({ type: SET_PREVIEWS, previews })
export const selectPreviews = () => store.getState().previews
