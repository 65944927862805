import { applyMiddleware, combineReducers, createStore } from 'redux'
import { accessCode } from './accessCode'
import { heaps } from './heaps'
import { previews } from './previews'

const middlewares = []
if (process.env.NODE_ENV !== 'production') {
  const { createLogger } = require('redux-logger')
  middlewares.push(createLogger({ collapsed: true }))
}

export const store = createStore(
  combineReducers({ accessCode, previews, heaps }),
  { },
  applyMiddleware(...middlewares)
)
