export const makeCancelable = promise => {
  let hasCanceled = false

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(
      result => hasCanceled ? reject(new Error({ isCanceled: true })) : resolve(result),
      error => hasCanceled ? reject(new Error({ isCanceled: true })) : reject(error)
    )
  })

  return {
    promise: wrappedPromise,
    cancel () {
      hasCanceled = true
    }
  }
}
