import PropTypes from 'prop-types'
import { Component } from 'react'
import { makeCancelable } from '../makeCancelable'
import { subscribe } from './index'

export const NULL_VALUE = Symbol('NULL_VALUE')

export class Connect extends Component {
  static propTypes = {
    selector: PropTypes.func.isRequired,
    loader: PropTypes.func.isRequired
  }

  state = {
    value: null
  }

  componentDidMount () {
    this.unsubscribe = subscribe(this.checkValue)
    this.checkValue()
  }

  componentWillUnmount () {
    this.unsubscribe()
    if (this.cancel != null) this.cancel()
  }

  checkValue = () => {
    const value = this.props.selector()
    if (value !== this.state.value) this.setState({ value })

    if (value == null) {
      // For now, we assume every value want's to get loaded aggressively
      if (this.cancel != null) this.cancel()
      const { cancel, promise } = makeCancelable(this.props.loader())
      promise.catch(error => console.warn('Errors from store reducer', error))
      this.cancel = cancel
    }
  }

  render () {
    const { value } = this.state
    return value == null ? null : this.props.children(value === NULL_VALUE ? null : value)
  }
}
