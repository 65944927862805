import HomeIcon from '@material-ui/icons/Home'
import { Heap, MaterialHelpDrawer } from '@roguenet/heap'
import queryString from 'query-string'
import React, { Fragment } from 'react'
import styled from 'styled-components'
import { heapApi } from './Api'
import { Connect } from './store/Connect'

const StyledHeap = styled.div`
  .heap-lightBoxTitle {
    font-variation-settings: 'wght' 400;
    font-size: 22px;
  }
  
  .heap-lightBoxDescription {
    font-variation-settings: 'wght' 250;
    font-size: 20px;
  }
  
  .heap-lightBoxCopyright {
    font-variation-settings: 'wght' 250;
    font-size: 12px;
  }
`

const Styled404 = styled.div`
  font-variation-settings: 'wght' 550;
  font-size: 40px;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`

const InlineHomeIcon = styled(HomeIcon)`
  position: relative;
  top: 7px;
  border: 1px solid gray;
  border-radius: 16px;
`

// save the value when this component is mounted
const { mode } = queryString.parse(window.location.search)

const HeapPage = ({ match: { params: { heapName } }, history: { push } }) =>
  <Connect {...heapApi(heapName)}>{ heap => heap == null
    ? <Styled404>404: Unknown heap { heapName }</Styled404>
    : <StyledHeap>
      <Heap heap={heap} basePath={`/heaps/${heapName}/`} mode={mode}>{
        (navigate, cardContext) => <MaterialHelpDrawer
          navigate={navigate}
          cardContext={cardContext}
          additionalControls={[{
            onClick: () => push('/'),
            icon: <HomeIcon />,
            tooltip: 'Back to albums'
          }]}
          additionalControlsHelp={[
            <Fragment>
              The <InlineHomeIcon /> button will take you back to the list of Curtis family photo
              albums.
            </Fragment>
          ]}
        />
      }</Heap>
    </StyledHeap>
  }</Connect>

export default HeapPage
