import React from 'react'
import { Route, Switch } from 'react-router'
import HeapPage from './HeapPage'
import Index from './Index'
import '../main.css'

const SchurtisPhotos = () => <Switch>
  <Route exact path='/' component={Index} />
  <Route exact path='/heaps/:heapName/:imageName?' component={HeapPage} />
</Switch>

export default SchurtisPhotos
