import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const StyledHeapPreview = styled.div`
  width: 500px;
  border-radius: 4px;
  box-shadow: 2px 2px 20px #a0a0a0;
  padding: 20px;
  transition: box-shadow 250ms;
  display: flex;
  background: #eeeeee;
  margin: 32px;
  
  &:hover {
    box-shadow: 2px 2px 2px #a0a0a0;
  }
`

const PreviewImage = styled.div`
  width: 150px;
  height: 200px;
  border: 1px solid #a0a0a0;
  background: url(${({ src }) => src}) no-repeat center;
  background-size: cover;
  border-radius: 2px;
  flex-shrink: 0;
  flex-grow: 0;
  margin-right: 20px;
`

const PreviewTitle = styled.div`
  color: #2d2425;
  font-variation-settings: 'wght' 400, 'ital' 0.4;
  font-size: 30px;
`

const PreviewDescription = styled.div`
  font-variation-settings: 'wght' 300;
  font-size: 22px;
  margin-bottom: 10px;
`

const PreviewDate = styled.div`
  margin-top: auto;
  font-variation-settings: 'wght' 400;
  font-size: 18px;
  color: #b3526f;
`

const TextArea = styled.div`
  display: flex;
  flex-direction: column;
`

const HeapPreview = ({ path, name, preview, description, date }) =>
  <Link to={`/heaps/${path}`}>
    <StyledHeapPreview>
      <PreviewImage src={preview} />
      <TextArea>
        <PreviewTitle>{ name }</PreviewTitle>
        <PreviewDescription>{ description }</PreviewDescription>
        <PreviewDate>{ date }</PreviewDate>
      </TextArea>
    </StyledHeapPreview>
  </Link>

HeapPreview.propTypes = {
  path: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  preview: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired
}

export default HeapPreview
