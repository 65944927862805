import Button from '@material-ui/core/Button'
import purple from '@material-ui/core/colors/purple'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import React, { Component } from 'react'
import styled from 'styled-components'
import { selectAccessCode, setAccessCode } from './store'

const textFieldTheme = createMuiTheme({
  palette: {
    primary: purple
  },
  typography: {
    fontFamily: '"Jost", sans-serif'
  }
})

const buttonTheme = createMuiTheme({
  overrides: {
    MuiButton: {
      root: {
        background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        borderRadius: 3,
        border: 0,
        color: 'white',
        height: 48,
        padding: '0 30px',
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        fontFamily: 'Jost',
        fontVariationSettings: "'wght' 450"
      }
    }
  }
})

const LowerRightButton = styled(Button)`
  && {
    position: fixed;
    right: 10px;
    bottom: 10px;
    font-family: 'Jost', sans-serif;
    font-variation-settings: 'wght' 450;
  }
`

const FormWrapper = styled.form`
  padding: 0 30px 20px 30px;
  display: flex;
  flex-direction: column;
  font-family: 'Jost', sans-serif;
`

const FormRow = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  
  &:first-child {
    margin-top: 0;
  }
  
  &:last-child {
    margin-bottom: 0;
  }
`

const StyledDialogTitle = styled(DialogTitle)`
  && {
    align-self: center;
    
    > h2 {
      font-size: 30px;
      font-family: 'Jost', sans-serif;
    }
  }
`

const DialogDescription = styled.div`
  font-size: 20px;
`

export default class AccessCodeDialog extends Component {
  state = {
    open: false,
    accessCode: selectAccessCode()
  }

  open = () => this.setState({ open: true })
  close = () => this.setState({ open: false })

  handleChange = event => this.setState({ accessCode: event.target.value })

  setAccessCode = event => {
    event.preventDefault()
    setAccessCode(this.state.accessCode)
    this.close()
  }

  render () {
    return <MuiThemeProvider theme={buttonTheme}>
      <LowerRightButton color='primary' variant='contained' onClick={this.open} >
        Use Access Code
      </LowerRightButton>
      <Dialog open={this.state.open} onClose={this.close}>
        <StyledDialogTitle>Set access code</StyledDialogTitle>
        <FormWrapper onSubmit={this.setAccessCode}>
          <FormRow>
            <DialogDescription>
              Some albums are only visible when the correct access code is applied. If you have
              received an access code, supply it below to gain access to all albums that code has
              access to.
            </DialogDescription>
          </FormRow>
          <FormRow>
            <MuiThemeProvider theme={textFieldTheme}>
              <TextField
                onChange={this.handleChange}
                value={this.state.accessCode}
                label='Access code'
              />
            </MuiThemeProvider>
          </FormRow>
          <FormRow><Button color='primary' variant='contained' type='submit'>Save</Button></FormRow>
        </FormWrapper>
      </Dialog>
    </MuiThemeProvider>
  }
}
